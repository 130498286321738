<template>
  <router-view/>
</template>

<script>

export default {
  name: 'index',
  created () {
    this.$store.dispatch('auth/setAccessToken')
  }
}
</script>

<style scoped>

</style>

